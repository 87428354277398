import { useContext, useState } from "react";
import { StepperContext } from "../../contexts/StepperContext";
import TextField from "../TextField";
import allSportsGender from "../../json/allSportsGender.json"
// import TextField from "../TextField";
function AddTeam() {
  const { userData, setUserData } = useContext(StepperContext);
  const initialTeamValue = (allSportsGender[userData.sport].subEvents)? userData.sport === "Taekwondo(Mix)"? [{ player: '', subEvent: '' ,gender: '' }] : [{ player: '', subEvent: '' }]: [{ player: '' }];
  const [team, setTeam] = useState(userData['team'] || initialTeamValue);
  


  const handleTeamAdd = () => {
    if (allSportsGender[userData.sport].subEvents) {
      if (userData.sport === "Taekwondo(Mix)"){
        setTeam([...team, { player: "", subEvent: "",gender: "" }]);
      }
      else{
        setTeam([...team, { player: "", subEvent: "" }]);
      }
      
    }
    else {
      setTeam([...team, { player: "" }]);
    }
    setUserData({ ...userData, team: team });
  };
  const handleTeamRemove = (index) => {
    const list = [...team];
    list.splice(index, 1);
    setTeam(list);
    setUserData({ ...userData, team: list });
  };
  const handleTeamChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...team];
    list[index][name] = value;
    setTeam(list);
    console.log("list===" + list)
    setUserData({ ...userData, team: team });
  };
  console.log(userData)
  return (
    <div className="flex flex-col w-full">
      <div className="mb-10 mt-5">
        <h5 className="text-black font-bold text-xl mb-2 md:text-2xl  ">
          Add Team members
        </h5>
        <p className="text-sm tracking-normal font-medium text-gray-600">
          <span style={{ fontWeight: 'bold', color: 'red' }}> Please Add players including Captain and Vice-captain. </span> <br />
          {/* Player limit in {userData.sport} is between {allSportsGender[userData.sport]["min"]} and {allSportsGender[userData.sport]["max"]} */}
        </p>
      </div>
      {team.map((player, index) => (
        <div key={index} className="w-full flex-1 mb-5">
          <div className="flex flex-row ">
            <div className="flex-1">
              <TextField
                name={"player"}
                label={"Player " + (1 + index)}
                placeholder={"Player " + (1 + index) + " name"}
                value={player.player}
                type="text"
                onChange={(e) => handleTeamChange(e, index)}
                required
              />

              {allSportsGender[userData.sport].subEvents ? (
                <select
                  name={"subEvent"}
                  value={player.subEvent}
                  onChange={(e) => handleTeamChange(e, index)}
                  selectedValue={player.subEvent}
                  className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                >
                  {console.log("====" + player.subEvent)}
                  <option value="" selected> {"select"}</option>
                  {allSportsGender[userData.sport]["options"].map(event =>
                    <option value={event}> {event}</option>
                  )}
                </select>) : null}
            </div>
            <div className="flex-1">
              <TextField
                name={"number"}
                label={"Contact of Player " + (1 + index)}
                placeholder={"Contact of Player " + (1 + index)}
                value={player.number}
                type="text"
                onChange={(e) => handleTeamChange(e, index)}
                required
              />
              {(userData.sport == "Taekwondo(Mix)") && (<select
                name={"gender"}
                value={player.gender}
                onChange={(e) => handleTeamChange(e, index)}
                selectedValue={player.gender}
                className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                required
              >
                {console.log("====" + player.gender)}
                <option value="" selected> {"select"}</option>
                <option value="male"> {"male"}</option>
                <option value="female"> {"female"}</option>
              </select>)}
            </div>
            <div className="flex-none flex items-center justify-center h-full ml-2">
              {team.length > 1 && (
                <button type="button" className="inline-flex flex-row w-full h-full py-3 px-2 md:px-4 items-center bg-white text-red-600 focus:shadow-sm uppercase  rounded-md shadow-md  font-semibold cursor-pointer border-2 border-red-600  transition duration-200 ease-in-out " onClick={() => handleTeamRemove(index)}>
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>
                </button>
              )}
            </div>
          </div>


          {team.length - 1 === index && team.length < 50 && (
            <button type="button" className="inline-flex flex-row items-center bg-white text-blue-600 focus:shadow-sm uppercase py-2 px-4 rounded-md shadow-md mr-2 mt-4 font-semibold cursor-pointer border-2 border-blue-600  transition duration-200 ease-in-out " onClick={handleTeamAdd}>
              <svg
                className="w-5 h-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path>
                </g>
              </svg>
              Add more
            </button>
          )}
        </div>
      ))}
    </div>
  );
}
export default AddTeam;
